<template>
  <b-card>
    <b-card-header><b class="h1">Basic information</b></b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="6">
          <b-form-group label="Avatar:" label-cols-md="3">
            <div
              class="d-flex align-items-center justify-content-center"
              style="max-width: 200px;"
            >
              <b-aspect aspect="1:1">
                <b-img-lazy
                  :src="model.avatar"
                  fluid
                  thumbnail
                  rounded
                  style="position: absolute; max-width: 200px;"
                />
              </b-aspect>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-form-group label="Title:" label-cols-md="3">
                <div class="d-flex align-items-center h-100">
                  {{ model.title }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Slug:" label-cols-md="3">
                <div class="d-flex align-items-center h-100">
                  {{ model.slug }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Blog category:" label-cols-md="3">
                <div class="d-flex align-items-center h-100">
                  {{ model.blog_category_id }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Position:" label-cols-md="3">
                <div class="d-flex align-items-center h-100">
                  {{ model.position }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Is active:" label-cols-md="3">
                <ActiveStatus :value="model.is_active" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Meta keyword:" label-cols-md="3">
                <div class="d-flex align-items-center h-100">
                  {{ model.meta_keyword }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Meta description:" label-cols-md="3">
                <div class="d-flex align-items-center h-100">
                  {{ model.meta_description }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Created at:" label-cols-md="3">
                <div class="d-flex align-items-center h-100">
                  {{ model.created_at }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group label="Short content:" label-cols-md="3">
            <div class="d-flex align-items-center h-100">
              {{ model.short_content }}
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Content:" label-cols-md="3">
            <div class="d-flex align-items-center h-100">
              {{ model.content }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BImgLazy,
  BAspect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ActiveStatus from '@/views/components/active-status/index.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BImgLazy,
    BAspect,
    ActiveStatus,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      model: {},
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_blog/${this.$route.params.id}?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
