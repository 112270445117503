<template>
  <div
    class="d-flex align-items-center h-100"
    :class="{'text-danger': active.value === 0, 'text-success': active.value === 1}"
  >
    {{ active.text }}
  </div>
</template>

<script>
import Config from '@/@config/index'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      activeOptions: Config.activeOptions,
      active: {
        value: 0,
        text: 'In active',
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.active = this.activeOptions.filter(x => x.value === Number(val))
        // eslint-disable-next-line prefer-destructuring
        this.active = this.active[0]
      }
    },
  },
  mounted() {
    this.active = this.activeOptions.filter(x => x.value === Number(this.value))
    // eslint-disable-next-line prefer-destructuring
    this.active = this.active[0]
  },
}
</script>
